<template>
    <div class="modal-body">
        <form v-on:submit.prevent="addCoupon" :data-vv-scope="'add-coupon'">
        <div class="col-sm-6">
          <div class="green-border-box in-padd white-bg">
            <h3 style="text-align:center;">Basic Details</h3>
            <div class="row">
                <div class="col-xs-12 col-sm-6">
                    <input
                    type="text"
                    placeholder="Coupon Name"
                    name="name"
                    v-model="coupon.name"
                    v-validate="'required'"
                    maxlength="23"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.name') }"
                    >
                </div>
                <div class="col-xs-12 col-sm-6">
                    <input
                    type="text"
                    placeholder="Coupon Link"
                    name="link"
                    v-model="coupon.link"
                    v-validate="{
                        required: true,
                        url: { require_protocol: true }
                      }"
                    maxlength="1024"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.link') }"
                    >
                </div>
                <div class="col-xs-12 col-sm-12">
                    <textarea
                    v-model="coupon.description"
                    placeholder="Special Notes"
                    class="input textarea"
                    name="description"
                    rows="3"
                    v-validate="'required'"
                    maxlength="2000"
                    :class="{'input': true, 'is-danger': errors.has('add-coupon.description') }"
                    ></textarea>
                </div>
            </div>
            <h4 class="text-center">Add Coupon Image</h4>
            <div class="row">
            <div class="col-xs-12 croppa-tip"></div>
            <div
                class="col-xs-12 col-sm-12 col-md-12 text-center"
                v-for="(cropper, i) in croppers"
                :key="'coupon-image-' + i"
            >
                <div class="upload-remove-container">
                <croppa
                    :width="400"
                    :heigh="300"
                    v-model="croppers[i]"
                    placeholder="Select Image"
                    initial-size="contain"
                    :placeholder-font-size="25"
                    :show-remove-button="false"
                    :initial-image="initialImages[i]"
                ></croppa>
                <button
                    v-if="croppers[i] && croppers[i].hasImage()"
                    class="upload-remove"
                    type="button"
                    @click="croppers[i].remove()"
                ></button>
                </div>
            </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="green-border-box in-padd white-bg">
            <h3 style="text-align:center;">Choose States For Launch Coupons</h3>
            <div class="filter-row">
                <div class="">
                  <select v-model="countryId" class="btn-select-box" @change="getStates">
                    <option value="">Select Country</option>
                    <option v-for="country in countries" :key="country.id" :value="country.id">{{country.name}}</option>
                  </select>
                </div>
            </div>
            <div class="green-border-box mb10 checklist" v-if="states.length > 0 || selectedStates.length > 0 ">
              <div class="col-sm-6">
                <div class="checklist-head">
                  <input type="checkbox" id="all-stores" v-model="selectAll" @change="onSelectAll">
                  <label for="all-stores">Select States</label>
                </div>
                <VuePerfectScrollbar class="checklist-contain" >
                  <ul class="checklist-body" >
                    <li
                      v-for="state in states"
                      :key="state.id"
                      @click="setSelected(state, !state.selected)"
                    >
                      <span class="checklist-item">
                        <span :title="state.name">{{state.name}}</span>
                          <img
                            v-if="inSelectedStatesList(state)"
                            src="/img/Create Pickl/storenameSelect_icon.png"
                          >
                      </span>
                    </li>
                  </ul>
                </VuePerfectScrollbar>
              </div>
              <div class="col-sm-6">
                <div class="checklist-head">
                  <label for="all-stores" style="display:block;">States Ready To Blast</label>
                </div>
                <VuePerfectScrollbar class="checklist-contain" >
                  <ul class="checklist-body" v-if="selectedStates.length > 0 ">
                    <li
                      v-for="(ss, index) in selectedStates"
                      :key="ss.id"
                    >
                      <span class="checklist-item">
                        <span :title="ss.name">{{ss.name}}</span>
                        <img
                          src="/img/Create Pickl/cancel_purple.png"
                          class="remove-icon"
                          @click="removeRetailer(index)"
                          style="width:24px;height:24px;"
                        >
                      </span>
                    </li>
                  </ul>
                  <h3 v-else style="text-align:center;">No States Selected</h3>
                </VuePerfectScrollbar>
              </div>
            </div>

            <h3 v-if="editCouponFlag" style="text-align:center;margin-top: 30px;">Currently Selected States</h3>
            <div v-if="editCouponFlag && couponStates.length > 0" class="green-border-box mb10 checklist">
              <div class="col-sm-12">
                <VuePerfectScrollbar class="checklist-contain" >
                  <ul class="checklist-body" >
                    <li
                      v-for="(cs, index) in couponStates"
                      :key="cs.id"
                      @click="removeCouponState(cs.id, index)"
                    >
                      <span class="checklist-item">
                        <span :title="cs.state.name">{{cs.state.name}}</span>
                          <img
                            src="/img/Create Pickl/cancel_purple.png"
                          >
                      </span>
                    </li>
                  </ul>
                </VuePerfectScrollbar>
              </div>
            </div>
            <div v-else class="green-border-box mb10 checklist">
              <h3 style="text-align:center;">No State Selected!</h3>
            </div>
          </div>
        </div>
        <input
            type="submit"
            class="btn-lg-green m0"
            :value="editCouponFlag?'UPDATE COUPON':'ADD COUPON'"
            :disabled="busy"
            style="margin-top:20px;"
        >
        </form>
    </div>
</template>
<script type="text/javascript">
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    VuePerfectScrollbar
  },
  name: 'CouponPopup',
  props: ['popupId', 'couponDetails', 'editCouponFlag'],
  data () {
    return {
      busy: false,
      coupon: {
        name: '',
        link: '',
        description: '',
        isActive: 1,
        images: [],
        type: 'GLOBAL'
      },
      croppers: new Array(1),
      couponImages: new Array(1),
      limitPerPage: 100,
      offset: 0,
      couponSections: [],
      initialImages: new Array(1),
      countries: [],
      countryId: 234,
      states: [],
      selectedStates: [],
      selectAll: false,
      couponStates: []
    }
  },
  methods: {
    removeCouponState (couponStateId, index) {
      this.couponStates.splice(index, 1)
      this.$store
        .dispatch('deleteCouponState', { id: couponStateId })
        .then(
          response => {
            console.log(response)
          },
          response => {}
        )
    },
    removeRetailer (index) {
      this.selectedStates.splice(index, 1)
    },
    setSelected (state, selected) {
      let isObjectPresent = this.selectedStates.findIndex((o) => o.id === state.id)
      if (isObjectPresent === -1) {
        this.selectedStates.push({ id: state.id, name: state.name })
      } else {
        this.selectedStates.splice(isObjectPresent, 1)
      }
    },
    getCountries () {
      this.$http.get('countries?service_enabled=true').then((response) => {
        this.countries = response.body.data
      })
    },
    inSelectedStatesList (state) {
      let isObjectPresent = this.selectedStates.find((o) => o.id === state.id)
      if (typeof isObjectPresent !== 'undefined') {
        return true
      } else {
        return false
      }
    },

    onSelectAll (event) {
      if (this.selectAll) {
        this.states.forEach((state) => {
          let isObjectPresent = this.selectedStates.find((o) => o.id === state.id)
          if (typeof isObjectPresent !== 'undefined') {
            return false
          }
          this.selectedStates.push({ id: state.id, name: state.name })
        })
      } else {
        this.states.forEach((state) => {
          let selectedStateIndex = this.selectedStates.findIndex((o) => o.id === state.id)
          if (selectedStateIndex !== -1) {
            this.removeRetailer(selectedStateIndex)
          }
        })
      }
    },

    getStates () {
      this.states = []
      this.$store.commit('showLoading', 'Loading States')
      this.$http.get('states?country_id=' + this.countryId).then((response) => {
        this.states = response.body.data
        this.$store.commit('hideMessage')
      })
    },
    generateImage (index) {
      let url = this.croppers[index].generateDataUrl()
      if (!url) {
        return
      }
      this.couponImages[index] = url
    },

    addCoupon () {
      for (var i = 0; i < 1; ++i) this.generateImage(i)
      this.$validator.validateAll('add-coupon').then((result) => {
        console.log(result)
        if (result && !this.busy) {
          this.busy = true
          if (this.editCouponFlag) {
            this.$store.dispatch('updateCoupon', this.coupon).then((response) => {
              this.saveCouponStates(this.coupon.id)
              if (this.couponImages.length > 0) {
                this.uploadCouponImage(this.coupon.id, this.couponImages[0])
              } else {
                this.busy = false
                this.$router.push('/sadmin/coupons')
              }
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-coupon' })
                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-coupon'
                  })
                }
              }
              this.busy = false
            })
          } else {
            this.$store.dispatch('saveCoupon', this.coupon).then((response) => {
              this.saveCouponStates(response.data.id)
              console.log(this.couponImages)
              if (this.couponImages.length > 0) {
                this.uploadCouponImage(response.data.id, this.couponImages[0])
              } else {
                this.busy = false
                this.$router.push('/sadmin/coupons')
              }
            }, (response) => {
              this.$emit('failed')
              for (var key in response.body) {
                this.$notify({ type: 'error', text: response.body[key][0] })
                let field = this.$validator.fields.find({ name: key, scope: 'add-coupon' })
                if (field) {
                  this.$validator.errors.add({
                    id: field.id,
                    field: key,
                    msg: response.body[key][0],
                    scope: 'add-coupon'
                  })
                }
              }
              this.busy = false
            })
          }
        } else {
          console.log(this.errors)
          this.$emit('failed')
        }
      })
    },
    uploadCouponImage (couponId, image) {
      if (typeof image !== 'undefined') {
        this.$store
          .dispatch('uploadCouponImage', {
            couponId: couponId,
            image: image
          })
          .then(
            response => {
              this.$notify({
                type: 'success',
                text: 'Coupon has been saved successfully'
              })
              this.busy = false
              this.$router.push('/sadmin/coupons')
            },
            response => {}
          )
      } else {
        this.$notify({
          type: 'success',
          text: 'Coupon has been saved successfully'
        })
        this.busy = false
        this.$router.push('/sadmin/coupons')
      }
    },

    saveCouponStates (couponId) {
      let stateIds = []
      this.selectedStates.forEach((state) => {
        stateIds.push(state.id)
      })
      this.$store
        .dispatch('saveBulkCouponStates', {
          couponId: couponId,
          stateIds: stateIds
        })
        .then(
          response => {
            console.log('coupon states saved')
          },
          response => {}
        )
    },

    resetForm () {
      this.croppers.forEach((x) => x.remove())
      let croppers = this.croppers
      Object.assign(this.$data, (this.$options.data).apply(this))
      this.croppers = croppers
      this.$nextTick(() => this.$validator.errors.clear('add-coupon'))
    },

    success () {
      (this.$refs.popupCloseButton).click()
      this.$emit('added')
      this.busy = false
    },
    getCouponStates (couponId) {
      this.$store
        .dispatch('getCouponStates', {
          couponId: couponId
        })
        .then(
          response => {
            this.couponStates = response.response.body.couponStates
          },
          response => {}
        )
    }
  },
  watch: {
    couponDetails () {
      this.coupon.name = this.couponDetails.name
      this.coupon.link = this.couponDetails.link
      this.coupon.description = this.couponDetails.description
      this.coupon.type = this.couponDetails.type
      this.coupon.id = this.couponDetails.id
      var i
      this.coupon.images = []
      this.initialImages = []
      for (i = 0; i < 1; i++) {
        this.croppers[i].refresh()
        if (this.editCouponFlag && (typeof this.couponDetails.image !== 'undefined')) {
          let image = new Image()
          image.setAttribute('crossorigin', 'anonymous')
          image.src = this.couponDetails.image
          this.initialImage = image
          this.initialImages[i] = image
        }
      }
      if (this.editCouponFlag === true) {
        this.getCouponStates(this.coupon.id)
      }
    }
  },
  mounted () {
    this.getCountries()
    this.getStates()
  }
}
</script>
<style scoped>

.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectcouponDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
.textarea {
  width: 100%;
}
.modal-dialog {
  /* max-width: 590px; */
  /* max-width: 690px; */
}
.modal-dialog.modal-lg {
  max-width: 100%;
}

p {
  color: #232323;
  font-size: 24px;
  text-align: center;
}

.attr-imgs {
  padding: 20px 10px 0;
  max-height: 650px;
  overflow: auto;
}

.photo {
  padding: 0 10px;
}

.img-contain {
  position: relative;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  overflow: hidden;
}

.img-contain:before {
  padding-top: 150%;
  display: block;
  content: " ";
}

/* .img-contain img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
} */

.img-contain .img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: white;
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
}

.img-contain .img-overlay span {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
}

.img-contain .img-overlay .img-tick {
  border-radius: 50%;
  background-color: #782b55;
  font-size: 17px;
  width: 26px;
  height: 26px;
  display: none;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 5px;
}

.vertical-center-transform {
  width: 100%;
  text-align: center;
}

.attr-imgs input[type="checkbox"] {
  display: none;
}
.multiselect {
  height:50px !important;
}

.multiselect__tags {
  height:50px !important;
  padding: 12px 40px 0 8px !important;
  border: 1px solid black !important;
}
.attr-imgs
  input[type="checkbox"]:checked
  + .img-contain
  .img-overlay
  .img-tick {
  display: block;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}

option {
  color: black;
  background-color: white;
}

.create-progress {
  position: relative;
  text-align: center;
  height: 65px;
}

hr {
  border-width: 2px;
  border-color: #dedede;
  margin: 15px 0 0;
  display: inline-block;
  transition: all 0.2s ease-out;
}

hr.done {
  border-color: #6f3053;
}

.progress-line {
  width: calc(100% - 58px);
  margin: 0 auto;
}

.step-part {
  text-align: center;
  position: absolute;
  top: 0;
}

.step-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #dedede;
  color: #646464;
  display: inline-block;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  padding: 6px;
  transition: all 0.2s ease-out;
  background-image: none;
}

.step-circle .step-label {
  color: black;
  position: absolute;
  bottom: -30px;
  left: 0;
  font-size: 17px;
  transition: all 0.2s ease-out;
}

.step-part:nth-child(1) {
  width: 64px;
  left: 0;
}

.step-part:nth-child(2) {
  width: 44px;
  left: calc(100% - 35px);
}

.step-circle.active {
  background-color: #00e05e;
  color: white;
}

.step-circle.active .step-label {
  color: #00e05e;
}

.step-circle.done {
  background-color: #6f3053;
  background-image: url("/img/Create Pickl/activestep_icon.png");
  background-size: 32px 32px;
  background-position: top left;
  font-size: 0;
}

.step-circle.done .step-label {
  color: #6f3053;
}

.filter-row {
  margin: 0 -5px;
}

.filter-control {
  width: 20%;
  padding: 0 5px;
  display: inline-block;
  margin-right: 0;
}

.filter-control .btn-select {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
}

h5.sub-heading {
  font-size: 16px;
  margin-top: 0;
}

.subtitle {
  font-size: 18px;
  text-align: center;
  color: black;
  margin-bottom: 10px;
}

.btn-positive {
  font-weight: 500;
  padding: 5px 15px;
  line-height: 18px;
  vertical-align: bottom;
}

.btn-positive img {
  vertical-align: text-top;
}

.product-btn {
  padding: 7px 15px;
}

.product-buttons {
  line-height: 54px;
}

.error-msg {
  margin-bottom: 10px;
  text-align: left;
}

.checklist .checklist-contain {
  max-height: 200px;
}

.checklist .checklist-contain .checklist-item span {
  width: 80%;
  display: inline-block;
}

.checklist .checklist-contain .checklist-item span.address {
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.checklist .checklist-contain li.selected .checklist-item span {
  width: calc(50% + 10px);
}

.checklist .checklist-contain li.selected .checklist-item span.address {
  width: calc(50% - 10px);
}

.pill-purple {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timepicker .mx-calendar-header {
  display: none;
}

.purple {
  color: #782b55
}

.font-14px {
  font-size:14px;
}

.font-14px::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size:14px;
}

@media only screen and (max-width: 767px) {
  .filter-control {
    width: 100%;
  }

  .checklist .checklist-contain .checklist-item span,
  .checklist .checklist-contain li.selected .checklist-item span,
  .checklist .checklist-contain li.selected .checklist-item span.address {
    width: 100%;
  }

  .pill-purple {
    white-space: normal;
  }
}
.disabled-gray:disabled {
  background:gainsboro;
  border: gainsboro;
}
select:disabled {
  background: gainsboro;
  border: 1px solid gray;
}
.input {
    border: 1px solid #6f3053;
    border-radius: 3px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 18px;
    color: black;
    max-width: 100%;
        width: 100%;
    padding: 13px 16px;
    height: 50px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.col-sm-6 {
  min-height: 140px !important;
}
</style>
